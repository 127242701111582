
import { defineComponent } from 'vue';
import ProviderCurrencyRate from '@/components/Modals/ProviderCurrencyRate.vue';

export default defineComponent({
    components: {
        ProviderCurrencyRate
    },
    async setup() {
        return {};
    }
});
